// import App from 'next/app'
import "../styles/main.scss";
import { useRef, Fragment, useEffect, useState } from "react";
import { DefaultSeo } from "next-seo";
import Head from "next/head";
import Nav from "../components/nav/nav.module";
import Context from "../components/context/context";
import Banner from "../components/banner/banner.module";
import LocationPicker from "../components/LocationPicker/location-picker.module";
import settings from "../util/settings";
import useLocationDetection from "../hooks/useLocationDetection";
import { saveLocationPreference } from "../util/preferences";

import { gsap } from "gsap";
import { ExpoScaleEase, RoughEase, SlowMo } from "gsap/dist/EasePack";
import { CSSRulePlugin } from "gsap/dist/CSSRulePlugin";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";

gsap.registerPlugin(
  CSSRulePlugin,
  ScrollToPlugin,
  ExpoScaleEase,
  RoughEase,
  SlowMo
);

function MyApp({ Component, pageProps }) {
  const [location, setLocation] = useState();
  const [locationPickingInProgress, setLocationPickingInProgress] =
    useState(false);
  const timer = useRef();

  const detectedLocation = useLocationDetection();

  useEffect(() => {
    setLocationPickingInProgress(!location);
    saveLocationPreference(location);
  }, [location]);

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);

    if (detectedLocation) setLocation(detectedLocation);
    else {
      timer.current = setTimeout(() => {
        setLocationPickingInProgress(true);
      }, 200);
    }
  }, [detectedLocation]);

  useEffect(() => {
    document.body.style.overflow = locationPickingInProgress ? 'hidden' : 'auto'
  }, [locationPickingInProgress])

  return (
    <Fragment>
      <DefaultSeo
        title="Kudos Golf"
        description="We provide customers with a state-of-the-art golf simulation experience, offering real time feedback data so you can improve your game all year round."
        additionalMetaTags={[
          {
            property: "image",
            content:
              "https://www.kudosgolf.com/assets/images/seo/kudos-golf-og.jpg",
          },
          {
            property: "og:image:secure_url",
            content:
              "https://www.kudosgolf.com/assets/images/seo/kudos-golf-og.jpg",
          },
        ]}
        openGraph={{
          description:
            "We provide customers with a state-of-the-art golf simulation experience, offering real time feedback data so you can improve your game all year round.",
          type: "website",
          locale: "en_IE",
          url: settings.host,
          site_name: "Kudos Golf",
          images: [
            {
              url: "https://www.kudosgolf.com/assets/images/seo/kudos-golf-og.jpg",
              alt: "Kudos Golf",
              height: 1200,
              width: 627,
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
        }}
      />
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#444444" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />

        <link
          rel="preload"
          href="/assets/fonts/roboto/roboto-light.ttf"
          as="font"
        />
        <link
          rel="preload"
          href="/assets/fonts/roboto/roboto-regular.ttf"
          as="font"
        />
      </Head>
      <Context.Provider value={{ location, locationPickingInProgress }}>
        <Nav openLocationPicker={() => setLocationPickingInProgress(true)} />

        {/* <Banner /> */}
        {locationPickingInProgress && (
          <LocationPicker
            updateLocation={(newLoc) => {
              if (location && location.address === newLoc.address) {
                setLocationPickingInProgress(false);
              } else {
                setLocation(newLoc);
              }
            }}
            currentLocation={location}
            setLocationPickingInProgress={setLocationPickingInProgress}
          />
        )}
        <Component {...pageProps} />
      </Context.Provider>
    </Fragment>
  );
}

export default MyApp;
